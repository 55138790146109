
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: 'confirm title'
        },
        modalId: {
            type: String,
            required: false,
            default: 'defaultConfirm',
        },
        confirmLabel: {
            type: String,
            required: false,
            default() {
                return '확인'
            }
        },
        cancelLabel: {
            type: String,
            required: false,
            default () {
                return '취소'
            }
        },
        onlyConfirm: {
            type: Boolean,
            default: false,
        },
        dialogClass: {
            type: String,
            required: false,
            default: '',
        },
    },
    data () {
        return {
            result: false,
        }
    },
    methods: {
        clickConfirm () {
            this.result = true
            this.$emit('get-confirm', this.result)
            this.hideModal()
        },
        clickCancel () {
            this.result = false
            this.$emit('get-confirm', this.result)
            this.hideModal()
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', this.modalId)
        },
        onClose() {
            this.$emit('hidden', this.result)
        },
    },
    computed: {
        cancelText () {
            return this.cancelLabel === '취소' ? this.$i18n.t('cancel') : this.cancelLabel 
        },
        confirmText() {
            return this.confirmLabel === '확인' ? this.$i18n.t('confirm') : this.confirmLabel
        }
    }
}
