
export default {
    props: {
      text : {
          type: String,
          required: false,
          default() {
            // return this.$t('markupResultNone.title')
            return '데이터가 없습니다.'
          }
      },
        fontSize: {
            type: String,
            required: false,
            default: 'h5',
        },
    },
    computed: {
        labelText() {
            return this.text === '데이터가 없습니다.' ? this.$i18n.t('markupResultNone.title') : this.text
        }
    }
}
