import locale77977fdd from '../../client/lang/ko.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ko","silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"ko","file":"ko.js"},{"code":"en","file":"en.js"}],
  defaultLocale: "ko",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ko","file":"ko.js"},{"code":"en","file":"en.js"}],
  localeCodes: ["ko","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "k",
  3: "o",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "c",
  14: "l",
  15: "i",
  16: "e",
  17: "n",
  18: "t",
  19: "/",
  20: "l",
  21: "a",
  22: "n",
  23: "g",
  24: "/",
  25: "k",
  26: "o",
  27: ".",
  28: "j",
  29: "s",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "n",
  35: ".",
  36: "j",
  37: "s",
  38: "\"",
  39: ":",
  40: "\"",
  41: ".",
  42: ".",
  43: "/",
  44: "c",
  45: "l",
  46: "i",
  47: "e",
  48: "n",
  49: "t",
  50: "/",
  51: "l",
  52: "a",
  53: "n",
  54: "g",
  55: "/",
  56: "e",
  57: "n",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: "}",
}

export const localeMessages = {
  'ko.js': () => Promise.resolve(locale77977fdd),
  'en.js': () => import('../../client/lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
