
export default {
    props: {
        isClose: {
            type: Boolean,
            required:false,
            default: false,
        },
    },
    data() {
        return {
            
        }
    },
    computed: {
        isVisibleLnb () {
            return this.$store.getters.isShowLNB
        },
        menuList() {
            return [
                {
                    name: this.$i18n.t('layoutAdminSideNavigationBar.admin'),
                    icon: 'clipboard-data',
                    url: '/admin',
                },
                // {
                //     name: '사용 현황 조회',
                //     icon: 'bar-chart-line',
                //     url: '/admin/statistics/train',
                // },
                // {
                //     name: '월별 Trend 조회',
                //     icon: 'graph-up',
                //     url: '/admin/statistics/trend',
                // },
                {
                    name: this.$i18n.t('layoutAdminSideNavigationBar.company'),
                    icon: 'people',
                    url: '/admin/company',
                },
                {
                    name: this.$i18n.t('layoutAdminSideNavigationBar.project'),
                    icon: 'receipt',
                    url: '/admin/project',
                },
                {
                    name: this.$i18n.t('layoutAdminSideNavigationBar.user'),
                    icon: 'person',
                    url: '/admin/user',
                },
                {
                    name: this.$i18n.t('layoutAdminSideNavigationBar.algorithm'),
                    icon: 'cpu',
                    url: '/admin/algorithm',
                },
                {
                    name: this.$i18n.t('layoutAdminSideNavigationBar.notice'),
                    icon: 'bell',
                    url: '/admin/notice',
                },
            ]
        }
    },
    methods: {
        toggleNav() {
            // this.$nuxt.$emit('lnb-close', !this.isClose)
            this.$store.dispatch('setLnbVisible', !this.isVisibleLnb)
        }
    },
}
