
export default {
  data() {
    return {};
  },
  computed: {
    isVisibleLnb() {
      return this.$store.getters.isShowLNB;
    },
    isDisuse() {
      return (
        this.$route.path.includes('/auth') ||
        this.$route.path.includes('/notice') ||
        this.$route.path.includes('/intro')
      );
    },
    isUplusTheme() {
      return this.$store.state.product_name === 'UPLUS';
    },
  },
};
