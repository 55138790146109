import { env, InferenceSession, Tensor } from 'onnxruntime-web';

// wasm 파일 경로 지정
// wasm 파일은 node_modules/onnxruntime-web/dist 경로에있는 파일을  client/static/wasm 에 복사 후 사용
env.wasm.wasmPaths = {
  'ort-wasm.wasm': '/wasm/ort-wasm.wasm',
  'ort-wasm-simd.wasm': '/wasm/ort-wasm-simd.wasm',
  'ort-wasm-threaded.wasm': '/wasm/ort-wasm-threaded.wasm',
};
export default ({ app }, inject) => {
  const ort = require('onnxruntime-web');
  inject('onnx', { InferenceSession, Tensor, ort });
};
