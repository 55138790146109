
export default {
  props: {
    isClosed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      userInfo: {},
      isLimitedUser: true,
    };
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo;
  },
  computed: {
    filteredMenuList() {
      return this.myMenuList.filter((menu) => {
        return (
          !(menu.noRoot && this.userInfo.role_id === 1) &&
          (!menu.techManagerOnly || (menu.techManagerOnly && this.userInfo.role_id === 2))
        );
      });
    },
    isMonitoring() {
      return this.$route.path.includes('resource-monitoring') && !this.isUsageStatus;
    },
    isMy() {
      return this.$route.path.includes('my');
    },
    isVisibleLnb() {
      return this.$store.getters.isShowLNB;
    },
    myMenuList() {
      let list = [];
      list = [
        {
          url: '/my',
          icon: 'person-badge',
          name: this.$i18n.t('layoutSideNavigationBar.menu.my'),
          exact: true,
          noRoot: true,
        },
        {
          url: '/my/change-password',
          icon: 'key',
          name: this.$i18n.t('layoutSideNavigationBar.menu.changePw'),
          exact: false,
        },
        // {
        //     url: '/my/statistics',
        //     icon: 'clipboard-data',
        //     name: '대시보드',
        //     exact: false,
        //     techManagerOnly: true
        // },
        {
          url: '/my/projects',
          icon: 'receipt',
          name: this.$i18n.t('layoutSideNavigationBar.menu.projects'),
          exact: false,
          techManagerOnly: true,
        },
        {
          url: '/my/user',
          icon: 'person',
          name: this.$i18n.t('layoutSideNavigationBar.menu.user'),
          exact: false,
          techManagerOnly: true,
        },
        {
          url: '/my/notifications',
          icon: 'bell',
          name: this.$i18n.t('layoutSideNavigationBar.menu.notifications'),
          exact: false,
        },
        {
          url: '/my/usage-projects',
          icon: 'receipt',
          name: this.$i18n.t('layoutSideNavigationBar.menu.usageProjects'),
          exact: false,
          isLimitedUser: this.isLimitedUser,
        },
      ];

      return list;
    },
    isKorean() {
      return this.$i18n.locale === 'ko';
    },
    isUsageStatus() {
      return this.$route.path.includes('resource-monitoring') || this.$route.path.includes('use-history');
    },
  },
  methods: {
    toggleNav() {
      this.$store.dispatch('setLnbVisible', !this.isVisibleLnb);
      // this.$nuxt.$emit('lnb-close', !this.isClosed)
    },
  },
};
