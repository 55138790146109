import Vuex from 'vuex';
import cookies from 'js-cookie';

export const strict = false;
const cookieparser = process.server ? require('cookieparser') : undefined;
const base_url = process.env.API_URL;
const base_url_server_side = process.env.API_URL_SERVER_SIDE;
const mavin_core_api = process.env.AICORE_API_URL;
const mavin_core_image = process.env.AICORE_IMAGE_URL;
const mavin_core_image_clean = process.env.AICORE_IMAGE_URL;

const except_projectCode_rules = process.env.EXCEPT_PROJECT_CODES;
const except_folderName_rules = process.env.EXCEPT_FOLDER_NAMES;

const product_name = process.env.product_name;
const fb = process.env.FILE_BROWSER_URL;

const monitoring_rate_interval = process.env.MONITORING_RATE_INTERVAL;
const monitoring_load_interval = process.env.MONITORING_LOAD_INTERVAL;

const cs_url = process.env.CS_CLIENT_URL;

const createStore = () => {
  return new Vuex.Store({
    state: {
      redirection: null,
      token: null,
      user: null,
      current_project: null,
      current_line: null,
      base_url: '',
      mavin_core_api: '',
      mavin_core_image: '',
      except_projectCodes: [],
      except_folderNames: [],
      fb: null,
      product_name: 'ONPREMISE_HPC',

      requestApiList: [],
      common: {},

      isShowLNB: true,

      cocoImagePerPage: 30,
      monitoring_rate_interval: 5000,
      monitoring_load_interval: 5000,
      strokeColors: [
        '#E52B50',
        '#FFBF00',
        '#9966CC',
        '#FBCEB1',
        '#7FFFD4',
        '#007FFF',
        '#CB4154',
        '#0000FF',
        '#DE5D83',
        '#CD7F32',
        '#0095B6',
        '#800020',
        '#007BA7',
        '#7FFF00',
        '#7B3F00',
        '#ff8a80',
        '#0047AB',
        '#FF7F50',
        '#DC143C',
        '#00FFFF',
        '#EDC9Af',
        '#50C878',
        '#FFD700',
        '#008001',
        '#4B0082',
        '#ff8a80',
        '#B57EDC',
        '#FFF700',
        '#BFFF00',
        '#FF00FF',
        '#800000',
        '#E0B0FF',
        '#000080',
        '#CC7722',
        '#808000',
        '#C8A2C8',
        '#FF4500',
        '#DA70D6',
        '#D1E231',
        '#CCCCFF',
        '#1C39BB',
        '#CC8899',
        '#6A0DAD',
        '#E30B5C',
        '#0F52BA',
        '#FA8072',
        '#FF2400',
        '#A7FC00',
        '#008080',
        '#FFFF00',
      ],
      routeName: null,
      isShowDownloadRequestModal: false,
      csPlatformUrl: '',
      csUrl: '',
      eventData: null,
    },
    mutations: {
      SET_API_URL(state, base_url) {
        state.base_url = base_url;
      },
      SET_AI_CORE_URL(state, mavin_core_api) {
        state.mavin_core_api = mavin_core_api + '/';
      },
      SET_AI_IMAGE_URL(state, mavin_core_image) {
        state.mavin_core_image = mavin_core_image + '/';
        state.mavin_core_image_clean = mavin_core_image;
      },
      SET_NAMING_RULES(state, { except_projectCodes, except_folderNames }) {
        state.except_projectCodes = except_projectCodes ? except_projectCodes.split(',') : [];
        state.except_folderNames = except_folderNames ? except_folderNames.split(',') : [];
      },
      SET_MONITOR_INTERVAL_TIME(state, { monitoring_rate_interval, monitoring_load_interval }) {
        state.monitoring_rate_interval = monitoring_rate_interval ? monitoring_rate_interval : 5000;
        state.monitoring_load_interval = monitoring_load_interval ? monitoring_load_interval : 5000;
      },
      SET_FB_URL(state, fb) {
        state.fb = fb;
      },
      SET_COMMON(state, commonData) {
        state.common = {
          ...state.common,
          ...commonData,
        };
      },
      SET_LNB_VISIBLE(state, visible) {
        state.isShowLNB = visible;
      },
      SET_IS_SHOW_DOWNLOAD_REQUEST_MODAL(state, visible) {
        state.isShowDownloadRequestModal = visible;
      },
      SET_CURRENT_PROJECT(state, project) {
        state.current_project = project;
      },
      SET_CURRENT_LINE(state, line) {
        state.current_line = line;
      },
      SET_REDIRECTION(state, path) {
        state.redirection = path;
      },
      REMOVE_REDIRECTION(state) {
        state.redirection = null;
      },
      SET_USER(state, information) {
        state.user = information;
      },
      REMOVE_USER(state) {
        state.user = null;
      },
      SET_TOKEN(state, token) {
        state.token = token;
      },
      REMOVE_TOKEN(state) {
        state.token = null;
      },
      SET_PRODUCT_NAME(state, product_name) {
        state.product_name = product_name;
      },
      REMOVE_PRODUCT_NAME(state) {
        state.product_name = null;
      },
      ADD_PREVIOUS_REQUEST(state, payload) {
        state.requestApiList.push(payload);
      },
      REMOVE_PREVIOUS_REQUEST(state, payload) {
        const payloadIdx = state.requestApiList.findIndex((request) => {
          return request.url === payload.url;
        });
        if (payloadIdx > -1) {
          state.requestApiList.splice(payloadIdx, 1);
        }
      },
      SET_CURR_ROUTE_NAME(state, payload) {
        state.routeName = payload;
      },
      SET_CS_PLATFORM_API_URL(state, payload) {
        state.csPlatformUrl = payload;
      },
      SET_CS_PLATFORM_URL(state, payload) {
        state.csUrl = payload;
      },
      SET_EVENT_DATA(state, data) {
        state.eventData = data;
      },
    },
    actions: {
      async nuxtServerInit({ commit, dispatch }, { req, route, app, store }) {
        // 앱 접근 (새로고침)
        // 테마 정보
        return true;

        await this.dispatch('setBasicInfo');

        let token, email;
        /*
                token = await app.$axios.$get('/api/auth/token')
                console.log(token)

                 */
        if (req) {
          if (req.headers.cookie) {
            // request 헤더에 엑세스토큰 쿠키 남아 있으면 불러오기
            token = cookieparser.parse(req.headers.cookie)['x-access-mavin'];
            email = cookieparser.parse(req.headers.cookie)['email'];
          }
        }
        if (token) {
          // 헤딩 엑세스 토큰으로 회원 로그인 유지
          await app.$axios.setToken(token, 'Bearer'); // axios 헤더 토큰 넣기
          await dispatch('setToken', { token });
        }
        //  else await store.dispatch('logout')
      },

      async setBasicInfo({ commit }) {
        await commit('SET_PRODUCT_NAME', this.$config.product_name);

        let apiUrl = this.$config.base_url;
        let aiCoreUrl = this.$config.mavin_core_api;
        let imageUrl = this.$config.mavin_core_image;
        if (this.$config.is_ssl) {
          if (this.$config.base_url.includes('http://')) {
            const baseUrl = this.$config.base_url.split('http://');
            apiUrl = 'https://' + baseUrl[1];
          }
          if (this.$config.mavin_core_api.includes('http://')) {
            const baseUrl = this.$config.mavin_core_api.split('http://');
            aiCoreUrl = 'https://' + baseUrl[1];
          }
          if (this.$config.mavin_core_image.includes('http://')) {
            const baseUrl = this.$config.mavin_core_image.split('http://');
            imageUrl = 'https://' + baseUrl[1];
          }
        }
        await commit('SET_API_URL', apiUrl);
        await commit('SET_AI_CORE_URL', aiCoreUrl);
        await commit('SET_AI_IMAGE_URL', imageUrl);
        await commit('SET_NAMING_RULES', {
          except_projectCodes: this.$config.except_projectCode_rules,
          except_folderNames: this.$config.except_folderName_rules,
        });
        await commit('SET_FB_URL', this.$config.fb);
        await commit('SET_MONITOR_INTERVAL_TIME', {
          monitoring_rate_interval: this.$config.monitoring_rate_interval,
          monitoring_load_interval: this.$config.monitoring_load_interval,
        });
      },
      async setToken({ commit }, { token, expiresIn }) {
        this.$axios.setToken(token, 'Bearer');
        sessionStorage.setItem('x-access-mavin', token);
        // sessionStorage.setItem('x-refresh-token-mavin', token)
        /*
                if(expiresIn) {
                    const expiryTime = new Date(new Date().getTime() + expiresIn * 1000)
                    if(this.$config.is_ssl || true){
                        const baseUrl = this.$config.base_url.split('://')[1].split('/api')[0]
                        await cookies.set('x-access-mavin', token, {expires: expiryTime, secure: 'secure', sameSite: 'none', httpOnly: true, domain: baseUrl})
                    } else {
                        await cookies.set('x-access-mavin', token, {expires: expiryTime})
                    }
                }
                */
        commit('SET_TOKEN', token);
        await this.dispatch('getCommons');
      },
      async getUserInfo({ commit }, { currentProject, currentLine }) {
        const userInfo = await this.$axios.$get('/api/user/information');
        const projects = await this.$axios.$get('/api/projects');

        await commit('SET_USER', {
          ...userInfo.data,
          reset_pwd_by_admin: userInfo.data.reset_admin ? userInfo.data.reset_admin : false,
          projects: projects.data,
        });

        let current_project = null;
        let current_line = null;

        /*
                if(cookieparser && req){
                    current_project = cookieparser.parse(req.headers.cookie)['current_project']
                    current_line = cookieparser.parse(req.headers.cookie)['current_line']

                    current_project = current_project? JSON.parse(current_project) : null
                    current_line = current_line? JSON.parse(current_line) : null
                }
                 */
        if (currentProject && currentProject.id) {
          current_project = projects.data.find((prj) => {
            return prj.id === currentProject.id;
          });
          if (currentLine && currentLine.id && current_project.lines) {
            current_line = current_project.lines.find((line) => {
              return line.id === currentLine.id;
            });
          }
        } else if (this.getters.current_project) {
          const lastCurrentPrj = this.getters.current_project;
          current_project = projects.data.find((prj) => {
            return prj.id === lastCurrentPrj.id;
          });
        }

        if (current_project) {
          await this.dispatch('setCurrentProject', current_project);
          if (current_line) {
            await this.dispatch('setCurrentLine', current_line);
          }
        } else if (projects.data.length > 0) {
          await this.dispatch('setCurrentProject', projects.data[0]);
        } else if (userInfo.data.default_project) {
          await this.dispatch('setCurrentProject', userInfo.data.default_project);
          if (!current_line && userInfo.data.default_project.lines) {
            await commit('SET_CURRENT_LINE', userInfo.data.default_project.lines[0]);
          }
        }
      },
      async getCommons({ commit }) {
        if (!this.state.common.labelingTypes && this.state.token) {
          await this.dispatch('getLabelingTypes', { commit });
        }
        if (!this.state.common.workTypes && this.state.token) {
          await this.dispatch('getWorkTypes', { commit });
        }
        if (!this.state.common.processTypes && this.state.token) {
          await this.dispatch('getProcessTypes', { commit });
        }
        if (!this.state.common.usageTypes && this.state.token) {
          await this.dispatch('getUsageTypes', { commit });
        }
        if (!this.state.common.permissions && this.state.token) {
          await this.dispatch('getPermissions', { commit });
        }
      },
      showAxiosError({ commit }, err) {
        if (String(err.status) === '401') {
          this.dispatch('logout');
        } else {
          let {
            data: { message, error },
          } = err;
          let errorMsg = error ? error : message;

          if (errorMsg) {
          }
        }
      },

      setLnbVisible({ commit }, visible) {
        commit('SET_LNB_VISIBLE', visible);
      },

      showDownloadRequestModal({ commit }, visible) {
        commit('SET_IS_SHOW_DOWNLOAD_REQUEST_MODAL', true);
      },
      hideDownloadRequestModal({ commit }, visible) {
        commit('SET_IS_SHOW_DOWNLOAD_REQUEST_MODAL', false);
      },

      addPreviousRequest({ commit }, payload) {
        commit('ADD_PREVIOUS_REQUEST', {
          url: payload.url,
          cancel: payload.cancel,
        });
      },
      removeRequestRequest({ commit }, payload) {
        commit('REMOVE_PREVIOUS_REQUEST', {
          url: payload.url,
        });
      },

      async getLabelingTypes({ commit }, path) {
        try {
          const labeling_type_list = await this.$axios.$get('/api/common/labeling_type_list');
          await commit('SET_COMMON', {
            labelingTypes: labeling_type_list.data,
          });
        } catch (e) {
          const { response: res } = e;
          this.dispatch('showAxiosError', {
            status: res.status,
            data: {
              message: res.data.message,
              error: e.config.url.includes(this.state.mavin_core_api) ? e.data.detail.message : res.data.error,
            },
          });
        }
      },
      async getWorkTypes({ commit }, path) {
        try {
          const work_type_list = await this.$axios.$get('/api/common/work_type_list');
          await commit('SET_COMMON', {
            workTypes: work_type_list.data,
          });
        } catch (e) {
          const { response: res } = e;
          this.dispatch('showAxiosError', {
            status: res.status,
            data: {
              message: res.data.message,
              error: e.config.url.includes(this.state.mavin_core_api) ? e.data.detail.message : res.data.error,
            },
          });
        }
      },
      async getProcessTypes({ commit }, path) {
        try {
          const process_type_list = await this.$axios.$get('/api/common/process_type_list');
          await commit('SET_COMMON', {
            processTypes: process_type_list.data,
          });
        } catch (e) {
          const { response: res } = e;
          this.dispatch('showAxiosError', {
            status: res.status,
            data: {
              message: res.data.message,
              error: e.config.url.includes(this.state.mavin_core_api) ? e.data.detail.message : res.data.error,
            },
          });
        }
      },
      async getUsageTypes({ commit }, path) {
        try {
          const usage_type_list = await this.$axios.$get('/api/common/usage_type_list');
          await commit('SET_COMMON', {
            usageTypes: usage_type_list.data,
          });
        } catch (e) {
          const { response: res } = e;
          this.dispatch('showAxiosError', {
            status: res.status,
            data: {
              message: res.data.message,
              error: e.config.url.includes(this.state.mavin_core_api) ? e.data.detail.message : res.data.error,
            },
          });
        }
      },
      async getPermissions({ commit }, path) {
        try {
          const permissions = await this.$axios.$get(`/api/common/permission`);
          await commit('SET_COMMON', {
            permissions: permissions.data,
          });
        } catch (e) {
          const { response: res } = e;
          this.dispatch('showAxiosError', {
            status: res.status,
            data: {
              message: res.data.message,
              error: e.config.url.includes(this.state.mavin_core_api) ? e.data.detail.message : res.data.error,
            },
          });
        }
      },

      async setRedirection({ commit }, path) {
        await commit('SET_REDIRECTION', path);
      },

      async removeRedirection({ commit }, path) {
        await commit('REMOVE_REDIRECTION', path);
      },

      async setInformation({ commit }, user) {
        await commit('SET_USER', user);
      },

      async setCurrentProject({ commit }, project) {
        const { id, code, created_at, title, hasDenied = false } = project;

        const prj = {
          userId: this.state.user.id,
          id: id,
          code: code,
          created_at: created_at,
          title: title,
          hasDenied: hasDenied,
        };

        await commit('SET_CURRENT_PROJECT', prj);
        if (this.$config.is_ssl) {
          await cookies.set('current_project', JSON.stringify(prj), { secure: 'secure' });
        } else {
          await cookies.set('current_project', JSON.stringify(prj));
        }
      },
      async setCurrentLine({ commit }, line) {
        if (this.$config.is_ssl) {
          await cookies.set('current_line', line, { secure: 'secure' });
        } else {
          await cookies.set('current_line', line);
        }
        await commit('SET_CURRENT_LINE', line);
      },
      async refreshToken({ dispatch }) {
        const { token, expiresIn } = await this.$axios.$post('/api/refresh-token');
        await dispatch('setToken', { token, expiresIn });
      },

      async logout({ commit }) {
        await this.$axios.$post('/api/auth/logout');
        sessionStorage.removeItem('x-access-mavin');
        await this.$axios.setToken(false);

        //cookies.remove('current_project')
        await commit('REMOVE_TOKEN');
        await commit('REMOVE_USER');
      },
      async setCurrRouteName({ commit }, path) {
        await commit('SET_CURR_ROUTE_NAME', path);
      },
      setCsPlatformUrl({ commit }) {
        commit('SET_CS_PLATFORM_API_URL', this.$config.cs_platform_url);
      },
      setCsUrl({ commit }) {
        // commit('SET_CS_PLATFORM_URL', process.env.CS_CLIENT_URL)
        commit('SET_CS_PLATFORM_URL', this.$config.cs_client_url);
      },
      triggerEvent({ commit }, data) {
        commit('SET_EVENT_DATA', data);
      },
    },
    getters: {
      previousRequest(state) {
        return [...state.requestApiList];
      },
      userInfo(state) {
        return state.user ?? {};
      },
      current_project(state) {
        return state.current_project;
      },
      current_line(state) {
        return state.current_line;
      },
      common(state) {
        return state.common;
      },
      cocoImagePerPage(state) {
        return state.cocoImagePerPage;
      },
      monitoring_rate_interval(state) {
        return state.monitoring_rate_interval;
      },
      monitoring_load_interval(state) {
        return state.monitoring_load_interval;
      },
      strokeColors(state) {
        return state.strokeColors;
      },
      isTrialUser(state) {
        const userInfo = state.user ?? {};
        return state.product_name === 'PUBLIC_GCP' && userInfo.role_id === 5;
      },
      isShowLNB(state) {
        return state.isShowLNB;
      },
      currentRouteName(state) {
        return state.routeName;
      },
      isShowDownloadRequestModal(state) {
        return state.isShowDownloadRequestModal;
      },
      csPlatform(state) {
        return state.csPlatformUrl;
      },
      csUrl(state) {
        return state.csUrl;
      },
      getEventData(state) {
        return state.eventData;
      },
    },
  });
};

export default createStore;
