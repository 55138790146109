import Vue from 'vue';
Vue.filter('digitComma', function (value) {
  if (!value) return value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

import vco from 'v-click-outside';
Vue.use(vco);

const validatePassword = function (password) {
  /**
   * 영문/숫자조합10자리이상 또는 영문/숫자/특수문자 조합 8자리 이상
   * 패스워드 입력실패 5회 제한
   * 패스워드 주기적 변경 강제화 (90일 이내)
   */
  if (password.length < 8) {
    return false;
  } else if (password.search(/\s/) != -1) {
    return false;
  } else if (
    password.search(/[0-9]/g) < 0 ||
    password.search(/[a-z]/gi) < 0 ||
    password.search(/[!@#$%^&*()-_=+]/gi) < 0
  ) {
    return false;
  }

  return true;
};
Vue.prototype.$validatePassword = validatePassword;
Vue.prototype.$createPassword = () => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()-_=+';

  function create() {
    let tmp,
      password = '';
    for (let i = 0; i < 10; i++) {
      const random = Math.floor(Math.random() * chars.length);
      tmp = chars.charAt(random);
      password = password + tmp;
    }
    if (validatePassword(password)) return password;
    else return create();
  }

  return create();
};
Vue.prototype.$maskingName = (inputName) => {
  const originName = inputName.split('');
  var eng = /[a-zA-Z]/; //영어
  //var kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글

  if (eng.test(inputName)) {
    originName.forEach(function (name, i) {
      if (i < 2 || i > originName.length - 2) return;
      originName[i] = '*';
    });
  } else {
    originName.forEach(function (name, i) {
      if (i !== 1) return;
      originName[i] = '*';
    });
  }
  return originName.join().replace(/,/g, '');
};
