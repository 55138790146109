import { render, staticRenderFns } from "./monitoring.vue?vue&type=template&id=71f2d2a4"
import script from "./monitoring.vue?vue&type=script&lang=js"
export * from "./monitoring.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutGlobalNavigationBar: require('/usr/src/app/client/components/layout/GlobalNavigationBar.vue').default,LayoutSideNavigationBar: require('/usr/src/app/client/components/layout/SideNavigationBar.vue').default,TransitionPage: require('/usr/src/app/client/components/TransitionPage.vue').default})
