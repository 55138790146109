
export default {
    props: {
        text: {
            type: String,
            required: false,
            default() {
                return '데이터를 불러오고 있습니다.'
            }
        }
    },
    computed: {
        loadingText() {
            return this.$i18n.t('loading')
        }
    }
}
