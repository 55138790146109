const errorThrowPathList = ['/aicomp/train/model', '/aicomp/train/inference'];

export default function ({ $axios, store, app, route, redirect }) {
  $axios.defaults.timeout = 60000;

  //disable redirect to error.vue
  app.nuxt.error = (err) => {
    if (
      err &&
      store &&
      store.state &&
      store.state.base_url &&
      store.state.mavin_core_api &&
      err.config &&
      (err.config.baseURL === store.state.base_url || err.config.baseURL === store.state.mavin_core_api)
    )
      return;

    if (err && err.message) {
      if (err.statusCode === 401 || err.message.includes('401')) {
        app.router.app.flashMessage.error({
          message: '세션이 만료되었습니다. 다시 로그인해주세요.',
        });
      } else {
        app.router.app.flashMessage.error({
          message: err.message,
        });
      }
    } else {
      app.router.app.flashMessage.error({
        message: '메세지가 없습니다.',
        ...err,
      });
    }
  };

  $axios.onRequest((config) => {
    if (config.baseURL.includes('http://') && app.$config.is_ssl) {
      const splitBaseUrl = config.baseURL.split('http://');
      config.baseURL = 'https://' + splitBaseUrl[1];
    }
    config.headers.common['Access-Control-Allow-method'] = '*';
    config.headers.common['Access-Control-Allow-Origin'] = '*';
    if (store.state.token) {
      $axios.setToken(store.state.token, 'Bearer');
    }

    const i18n = app && app.i18n ? app.i18n : {};
    config.headers.common['locale'] = i18n.locale ? i18n.locale : 'ko';

    const axiosSource = $axios.CancelToken.source();
    config.cancelToken = axiosSource.token;

    store.dispatch('addPreviousRequest', {
      url: config.url,
      token: axiosSource.token,
      cancel: axiosSource.cancel,
    });
    /*
        if(config.url.includes('http://')) {
            const splitUrl = config.url.split('http://')
            config.url = 'https://' + splitUrl[1]
        }
        */
  });
  $axios.onError(async (err) => {
    if ($axios.isCancel(err)) return err;
    const { response: res } = err;
    let {
      response: {
        data: { message, error },
      },
    } = err;
    //let message = error
    let errorMsg = error ? error : message;

    if (res.status === 401) {
      await store.dispatch('logout');
      console.log('route:', route);
      // window.location.href=window.location.origin + '/auth/login'
      // if (route.name !== "auth-login") {
      // app.router.replace('/auth/login')
      window.location.href = window.location.origin + '/auth/login';
      // }
      return;
    }

    if (res.config.url.includes('/aicomp/')) {
      errorMsg = res && res.data ? res.data.detail.message : '';
    }

    console.log(res.config.url);
    if (errorThrowPathList.includes(res.config.url)) {
      if (res.status >= 400 && res.status < 600) {
        throw err;
      }
    }

    if (errorMsg) {
      app.router.app.flashMessage.error({
        title: 'Error',
        message: errorMsg,
      });
    }
  });
}
