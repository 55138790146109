import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _16cee1a8 = () => interopDefault(import('../client/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _01c88a4d = () => interopDefault(import('../client/pages/cs/index.vue' /* webpackChunkName: "pages/cs/index" */))
const _180cc485 = () => interopDefault(import('../client/pages/intro/index.vue' /* webpackChunkName: "pages/intro/index" */))
const _70255bb6 = () => interopDefault(import('../client/pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _67d59eee = () => interopDefault(import('../client/pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _8f5f2718 = () => interopDefault(import('../client/pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _22d23d73 = () => interopDefault(import('../client/pages/privacyPolicy/index.vue' /* webpackChunkName: "pages/privacyPolicy/index" */))
const _54da2340 = () => interopDefault(import('../client/pages/resource-monitoring/index.vue' /* webpackChunkName: "pages/resource-monitoring/index" */))
const _e4b53668 = () => interopDefault(import('../client/pages/status.vue' /* webpackChunkName: "pages/status" */))
const _23d705be = () => interopDefault(import('../client/pages/train/index.vue' /* webpackChunkName: "pages/train/index" */))
const _94dce932 = () => interopDefault(import('../client/pages/use-history/index.vue' /* webpackChunkName: "pages/use-history/index" */))
const _48022fc4 = () => interopDefault(import('../client/pages/wireframe.vue' /* webpackChunkName: "pages/wireframe" */))
const _1bd732f0 = () => interopDefault(import('../client/pages/admin/algorithm/index.vue' /* webpackChunkName: "pages/admin/algorithm/index" */))
const _78260c96 = () => interopDefault(import('../client/pages/admin/company/index.vue' /* webpackChunkName: "pages/admin/company/index" */))
const _bb0dd62c = () => interopDefault(import('../client/pages/admin/history.vue' /* webpackChunkName: "pages/admin/history" */))
const _c1b3e216 = () => interopDefault(import('../client/pages/admin/notice/index.vue' /* webpackChunkName: "pages/admin/notice/index" */))
const _1af28832 = () => interopDefault(import('../client/pages/admin/project/index.vue' /* webpackChunkName: "pages/admin/project/index" */))
const _af0c2eb0 = () => interopDefault(import('../client/pages/admin/user/index.vue' /* webpackChunkName: "pages/admin/user/index" */))
const _86739fc8 = () => interopDefault(import('../client/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _66ae1e18 = () => interopDefault(import('../client/pages/monitoring/inspection/index.vue' /* webpackChunkName: "pages/monitoring/inspection/index" */))
const _61e6a334 = () => interopDefault(import('../client/pages/monitoring/line/index.vue' /* webpackChunkName: "pages/monitoring/line/index" */))
const _21b20943 = () => interopDefault(import('../client/pages/monitoring/model-history.vue' /* webpackChunkName: "pages/monitoring/model-history" */))
const _6ad0e952 = () => interopDefault(import('../client/pages/my/change-password/index.vue' /* webpackChunkName: "pages/my/change-password/index" */))
const _f03c343c = () => interopDefault(import('../client/pages/my/notifications/index.vue' /* webpackChunkName: "pages/my/notifications/index" */))
const _3e0d93d6 = () => interopDefault(import('../client/pages/my/projects/index.vue' /* webpackChunkName: "pages/my/projects/index" */))
const _f071fe02 = () => interopDefault(import('../client/pages/my/statistics/index.vue' /* webpackChunkName: "pages/my/statistics/index" */))
const _ca72ba7c = () => interopDefault(import('../client/pages/my/usage-projects/index.vue' /* webpackChunkName: "pages/my/usage-projects/index" */))
const _2e328332 = () => interopDefault(import('../client/pages/my/user/index.vue' /* webpackChunkName: "pages/my/user/index" */))
const _8a393d14 = () => interopDefault(import('../client/pages/resource-monitoring/train.vue' /* webpackChunkName: "pages/resource-monitoring/train" */))
const _d310c2ea = () => interopDefault(import('../client/pages/resource-monitoring/trend.vue' /* webpackChunkName: "pages/resource-monitoring/trend" */))
const _edabcd6c = () => interopDefault(import('../client/pages/train/dataset/index.vue' /* webpackChunkName: "pages/train/dataset/index" */))
const _0d402c2a = () => interopDefault(import('../client/pages/train/inference/index.vue' /* webpackChunkName: "pages/train/inference/index" */))
const _71144fe2 = () => interopDefault(import('../client/pages/train/learning/index.vue' /* webpackChunkName: "pages/train/learning/index" */))
const _59919152 = () => interopDefault(import('../client/pages/train/processing/index.vue' /* webpackChunkName: "pages/train/processing/index" */))
const _1b9ac99d = () => interopDefault(import('../client/pages/use-history/train.vue' /* webpackChunkName: "pages/use-history/train" */))
const _11a1f29c = () => interopDefault(import('../client/pages/use-history/trend.vue' /* webpackChunkName: "pages/use-history/trend" */))
const _782cb536 = () => interopDefault(import('../client/pages/admin/algorithm/create.vue' /* webpackChunkName: "pages/admin/algorithm/create" */))
const _97605e30 = () => interopDefault(import('../client/pages/admin/company/create.vue' /* webpackChunkName: "pages/admin/company/create" */))
const _59959ce3 = () => interopDefault(import('../client/pages/admin/notice/company/index.vue' /* webpackChunkName: "pages/admin/notice/company/index" */))
const _6d501a69 = () => interopDefault(import('../client/pages/admin/notice/create.vue' /* webpackChunkName: "pages/admin/notice/create" */))
const _6b12c8cc = () => interopDefault(import('../client/pages/admin/project/create.vue' /* webpackChunkName: "pages/admin/project/create" */))
const _b15e1694 = () => interopDefault(import('../client/pages/admin/statistics/train.vue' /* webpackChunkName: "pages/admin/statistics/train" */))
const _fa359c6a = () => interopDefault(import('../client/pages/admin/statistics/trend.vue' /* webpackChunkName: "pages/admin/statistics/trend" */))
const _e31111d4 = () => interopDefault(import('../client/pages/admin/user/create.vue' /* webpackChunkName: "pages/admin/user/create" */))
const _0f56e530 = () => interopDefault(import('../client/pages/admin/user/reset/index.vue' /* webpackChunkName: "pages/admin/user/reset/index" */))
const _1f28097a = () => interopDefault(import('../client/pages/monitoring/inspection/option.vue' /* webpackChunkName: "pages/monitoring/inspection/option" */))
const _11ab5082 = () => interopDefault(import('../client/pages/monitoring/inspection/report.vue' /* webpackChunkName: "pages/monitoring/inspection/report" */))
const _76b76435 = () => interopDefault(import('../client/pages/monitoring/inspection/sampling.vue' /* webpackChunkName: "pages/monitoring/inspection/sampling" */))
const _1dd5ad46 = () => interopDefault(import('../client/pages/monitoring/line/index_dummy.vue' /* webpackChunkName: "pages/monitoring/line/index_dummy" */))
const _63719976 = () => interopDefault(import('../client/pages/monitoring/line/report/index.vue' /* webpackChunkName: "pages/monitoring/line/report/index" */))
const _a94d9cb0 = () => interopDefault(import('../client/pages/my/projects/create.vue' /* webpackChunkName: "pages/my/projects/create" */))
const _245f8196 = () => interopDefault(import('../client/pages/my/statistics/train.vue' /* webpackChunkName: "pages/my/statistics/train" */))
const _6d37076c = () => interopDefault(import('../client/pages/my/statistics/trend.vue' /* webpackChunkName: "pages/my/statistics/trend" */))
const _5ba55937 = () => interopDefault(import('../client/pages/my/user/create.vue' /* webpackChunkName: "pages/my/user/create" */))
const _01ea15a2 = () => interopDefault(import('../client/pages/notices/pri/deep.vue' /* webpackChunkName: "pages/notices/pri/deep" */))
const _37b3b5f3 = () => interopDefault(import('../client/pages/notices/pri/edge.vue' /* webpackChunkName: "pages/notices/pri/edge" */))
const _1953b9f1 = () => interopDefault(import('../client/pages/notices/pri/setup-wizard.vue' /* webpackChunkName: "pages/notices/pri/setup-wizard" */))
const _3f99e97d = () => interopDefault(import('../client/pages/train/inference/add/index.vue' /* webpackChunkName: "pages/train/inference/add/index" */))
const _affe7f7e = () => interopDefault(import('../client/pages/train/inference/compare/index.vue' /* webpackChunkName: "pages/train/inference/compare/index" */))
const _5a2c7bf4 = () => interopDefault(import('../client/pages/train/learning/add/index.vue' /* webpackChunkName: "pages/train/learning/add/index" */))
const _8d9e6898 = () => interopDefault(import('../client/pages/train/learning/relearning/index.vue' /* webpackChunkName: "pages/train/learning/relearning/index" */))
const _ce16be2e = () => interopDefault(import('../client/pages/train/processing/add/index.vue' /* webpackChunkName: "pages/train/processing/add/index" */))
const _00d24a3b = () => interopDefault(import('../client/pages/admin/notice/company/create.vue' /* webpackChunkName: "pages/admin/notice/company/create" */))
const _b343e276 = () => interopDefault(import('../client/pages/monitoring/line/report/search.vue' /* webpackChunkName: "pages/monitoring/line/report/search" */))
const _4bf2d6be = () => interopDefault(import('../client/pages/train/processing/add/ai.vue' /* webpackChunkName: "pages/train/processing/add/ai" */))
const _51c70e3f = () => interopDefault(import('../client/pages/train/processing/add/ai.bak.vue' /* webpackChunkName: "pages/train/processing/add/ai.bak" */))
const _a8277786 = () => interopDefault(import('../client/pages/train/processing/add/augmentation.vue' /* webpackChunkName: "pages/train/processing/add/augmentation" */))
const _138d9e77 = () => interopDefault(import('../client/pages/train/processing/add/new.vue' /* webpackChunkName: "pages/train/processing/add/new" */))
const _26a8f230 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _be80e364 = () => interopDefault(import('../client/pages/admin/notice/company/_id/index.vue' /* webpackChunkName: "pages/admin/notice/company/_id/index" */))
const _aa126c60 = () => interopDefault(import('../client/pages/admin/user/reset/_id.vue' /* webpackChunkName: "pages/admin/user/reset/_id" */))
const _97e45aa8 = () => interopDefault(import('../client/pages/train/inference/compare/_id/index.vue' /* webpackChunkName: "pages/train/inference/compare/_id/index" */))
const _2b6ad984 = () => interopDefault(import('../client/pages/admin/notice/company/_id/edit.vue' /* webpackChunkName: "pages/admin/notice/company/_id/edit" */))
const _3872051a = () => interopDefault(import('../client/pages/admin/algorithm/_id/index.vue' /* webpackChunkName: "pages/admin/algorithm/_id/index" */))
const _1d5aec81 = () => interopDefault(import('../client/pages/admin/company/_id/index.vue' /* webpackChunkName: "pages/admin/company/_id/index" */))
const _3476b140 = () => interopDefault(import('../client/pages/admin/notice/_id/index.vue' /* webpackChunkName: "pages/admin/notice/_id/index" */))
const _48083a1d = () => interopDefault(import('../client/pages/admin/project/_id/index.vue' /* webpackChunkName: "pages/admin/project/_id/index" */))
const _095e6f93 = () => interopDefault(import('../client/pages/admin/user/_id/index.vue' /* webpackChunkName: "pages/admin/user/_id/index" */))
const _1a0f1c42 = () => interopDefault(import('../client/pages/monitoring/inspection/_id/index.vue' /* webpackChunkName: "pages/monitoring/inspection/_id/index" */))
const _09acd3c1 = () => interopDefault(import('../client/pages/my/projects/_id/index.vue' /* webpackChunkName: "pages/my/projects/_id/index" */))
const _1047645c = () => interopDefault(import('../client/pages/my/user/_id/index.vue' /* webpackChunkName: "pages/my/user/_id/index" */))
const _b0d2f02e = () => interopDefault(import('../client/pages/notices/cs/_id.vue' /* webpackChunkName: "pages/notices/cs/_id" */))
const _ede7d154 = () => interopDefault(import('../client/pages/train/inference/_id/index.vue' /* webpackChunkName: "pages/train/inference/_id/index" */))
const _1693a90e = () => interopDefault(import('../client/pages/admin/algorithm/_id/edit.vue' /* webpackChunkName: "pages/admin/algorithm/_id/edit" */))
const _2e950fec = () => interopDefault(import('../client/pages/admin/notice/_id/edit.vue' /* webpackChunkName: "pages/admin/notice/_id/edit" */))
const _73349ce2 = () => interopDefault(import('../client/pages/admin/project/_id/edit.vue' /* webpackChunkName: "pages/admin/project/_id/edit" */))
const _44fe9153 = () => interopDefault(import('../client/pages/admin/project/_id/user/index.vue' /* webpackChunkName: "pages/admin/project/_id/user/index" */))
const _b950154e = () => interopDefault(import('../client/pages/admin/user/_id/edit.vue' /* webpackChunkName: "pages/admin/user/_id/edit" */))
const _0ecec226 = () => interopDefault(import('../client/pages/monitoring/inspection/_id/cf.vue' /* webpackChunkName: "pages/monitoring/inspection/_id/cf" */))
const _232cba98 = () => interopDefault(import('../client/pages/monitoring/inspection/_id/od.vue' /* webpackChunkName: "pages/monitoring/inspection/_id/od" */))
const _0eb5536b = () => interopDefault(import('../client/pages/my/projects/_id/edit.vue' /* webpackChunkName: "pages/my/projects/_id/edit" */))
const _172c5e2f = () => interopDefault(import('../client/pages/my/projects/_id/user/index.vue' /* webpackChunkName: "pages/my/projects/_id/user/index" */))
const _46d4598c = () => interopDefault(import('../client/pages/my/user/_id/edit.vue' /* webpackChunkName: "pages/my/user/_id/edit" */))
const _78f6e80f = () => interopDefault(import('../client/pages/train/inference/_id/cf.vue' /* webpackChunkName: "pages/train/inference/_id/cf" */))
const _e5563efe = () => interopDefault(import('../client/pages/train/inference/_id/od.vue' /* webpackChunkName: "pages/train/inference/_id/od" */))
const _d75aed00 = () => interopDefault(import('../client/pages/train/inference/_id/sg.vue' /* webpackChunkName: "pages/train/inference/_id/sg" */))
const _10617e10 = () => interopDefault(import('../client/pages/train/learning/_id/cf.vue' /* webpackChunkName: "pages/train/learning/_id/cf" */))
const _0c2d396a = () => interopDefault(import('../client/pages/train/learning/_id/od.vue' /* webpackChunkName: "pages/train/learning/_id/od" */))
const _132ae269 = () => interopDefault(import('../client/pages/train/learning/_id/sg.vue' /* webpackChunkName: "pages/train/learning/_id/sg" */))
const _7f39e866 = () => interopDefault(import('../client/pages/train/processing/_id/cf/index.vue' /* webpackChunkName: "pages/train/processing/_id/cf/index" */))
const _9c777950 = () => interopDefault(import('../client/pages/train/processing/_id/od/index.vue' /* webpackChunkName: "pages/train/processing/_id/od/index" */))
const _e18e1dd2 = () => interopDefault(import('../client/pages/train/processing/_id/sg/index.vue' /* webpackChunkName: "pages/train/processing/_id/sg/index" */))
const _1e510724 = () => interopDefault(import('../client/pages/admin/project/_id/user/manager.vue' /* webpackChunkName: "pages/admin/project/_id/user/manager" */))
const _7e2edebc = () => interopDefault(import('../client/pages/monitoring/inspection/_id/result/cf.vue' /* webpackChunkName: "pages/monitoring/inspection/_id/result/cf" */))
const _5572edd8 = () => interopDefault(import('../client/pages/monitoring/inspection/_id/result/od.vue' /* webpackChunkName: "pages/monitoring/inspection/_id/result/od" */))
const _2274fb6c = () => interopDefault(import('../client/pages/my/projects/_id/user/manager.vue' /* webpackChunkName: "pages/my/projects/_id/user/manager" */))
const _2da8c259 = () => interopDefault(import('../client/pages/train/inference/_id/result/ad.vue' /* webpackChunkName: "pages/train/inference/_id/result/ad" */))
const _312ea299 = () => interopDefault(import('../client/pages/train/inference/_id/result/cf.vue' /* webpackChunkName: "pages/train/inference/_id/result/cf" */))
const _458c9b0b = () => interopDefault(import('../client/pages/train/inference/_id/result/od.vue' /* webpackChunkName: "pages/train/inference/_id/result/od" */))
const _4c8a440a = () => interopDefault(import('../client/pages/train/inference/_id/result/sg.vue' /* webpackChunkName: "pages/train/inference/_id/result/sg" */))
const _71c2191f = () => interopDefault(import('../client/pages/train/processing/_id/cf/index.back.vue' /* webpackChunkName: "pages/train/processing/_id/cf/index.back" */))
const _1c051db9 = () => interopDefault(import('../client/pages/train/processing/_id/cf/result.vue' /* webpackChunkName: "pages/train/processing/_id/cf/result" */))
const _7694c56d = () => interopDefault(import('../client/pages/train/processing/_id/od/index.back.vue' /* webpackChunkName: "pages/train/processing/_id/od/index.back" */))
const _3ac62107 = () => interopDefault(import('../client/pages/train/processing/_id/od/result.vue' /* webpackChunkName: "pages/train/processing/_id/od/result" */))
const _5df1d70e = () => interopDefault(import('../client/pages/train/processing/_id/sg/index.back.vue' /* webpackChunkName: "pages/train/processing/_id/sg/index.back" */))
const _0be72b28 = () => interopDefault(import('../client/pages/train/processing/_id/sg/result.vue' /* webpackChunkName: "pages/train/processing/_id/sg/result" */))
const _3a36fbdc = () => interopDefault(import('../client/pages/notices/_id.vue' /* webpackChunkName: "pages/notices/_id" */))
const _1044e865 = () => interopDefault(import('../client/pages/pdfViewer/_id.vue' /* webpackChunkName: "pages/pdfViewer/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _16cee1a8,
    name: "admin"
  }, {
    path: "/cs",
    component: _01c88a4d,
    name: "cs"
  }, {
    path: "/intro",
    component: _180cc485,
    name: "intro"
  }, {
    path: "/monitoring",
    component: _70255bb6,
    name: "monitoring"
  }, {
    path: "/my",
    component: _67d59eee,
    name: "my"
  }, {
    path: "/notices",
    component: _8f5f2718,
    name: "notices"
  }, {
    path: "/privacyPolicy",
    component: _22d23d73,
    name: "privacyPolicy"
  }, {
    path: "/resource-monitoring",
    component: _54da2340,
    name: "resource-monitoring"
  }, {
    path: "/status",
    component: _e4b53668,
    name: "status"
  }, {
    path: "/train",
    component: _23d705be,
    name: "train"
  }, {
    path: "/use-history",
    component: _94dce932,
    name: "use-history"
  }, {
    path: "/wireframe",
    component: _48022fc4,
    name: "wireframe"
  }, {
    path: "/admin/algorithm",
    component: _1bd732f0,
    name: "admin-algorithm"
  }, {
    path: "/admin/company",
    component: _78260c96,
    name: "admin-company"
  }, {
    path: "/admin/history",
    component: _bb0dd62c,
    name: "admin-history"
  }, {
    path: "/admin/notice",
    component: _c1b3e216,
    name: "admin-notice"
  }, {
    path: "/admin/project",
    component: _1af28832,
    name: "admin-project"
  }, {
    path: "/admin/user",
    component: _af0c2eb0,
    name: "admin-user"
  }, {
    path: "/auth/login",
    component: _86739fc8,
    name: "auth-login"
  }, {
    path: "/monitoring/inspection",
    component: _66ae1e18,
    name: "monitoring-inspection"
  }, {
    path: "/monitoring/line",
    component: _61e6a334,
    name: "monitoring-line"
  }, {
    path: "/monitoring/model-history",
    component: _21b20943,
    name: "monitoring-model-history"
  }, {
    path: "/my/change-password",
    component: _6ad0e952,
    name: "my-change-password"
  }, {
    path: "/my/notifications",
    component: _f03c343c,
    name: "my-notifications"
  }, {
    path: "/my/projects",
    component: _3e0d93d6,
    name: "my-projects"
  }, {
    path: "/my/statistics",
    component: _f071fe02,
    name: "my-statistics"
  }, {
    path: "/my/usage-projects",
    component: _ca72ba7c,
    name: "my-usage-projects"
  }, {
    path: "/my/user",
    component: _2e328332,
    name: "my-user"
  }, {
    path: "/resource-monitoring/train",
    component: _8a393d14,
    name: "resource-monitoring-train"
  }, {
    path: "/resource-monitoring/trend",
    component: _d310c2ea,
    name: "resource-monitoring-trend"
  }, {
    path: "/train/dataset",
    component: _edabcd6c,
    name: "train-dataset"
  }, {
    path: "/train/inference",
    component: _0d402c2a,
    name: "train-inference"
  }, {
    path: "/train/learning",
    component: _71144fe2,
    name: "train-learning"
  }, {
    path: "/train/processing",
    component: _59919152,
    name: "train-processing"
  }, {
    path: "/use-history/train",
    component: _1b9ac99d,
    name: "use-history-train"
  }, {
    path: "/use-history/trend",
    component: _11a1f29c,
    name: "use-history-trend"
  }, {
    path: "/admin/algorithm/create",
    component: _782cb536,
    name: "admin-algorithm-create"
  }, {
    path: "/admin/company/create",
    component: _97605e30,
    name: "admin-company-create"
  }, {
    path: "/admin/notice/company",
    component: _59959ce3,
    name: "admin-notice-company"
  }, {
    path: "/admin/notice/create",
    component: _6d501a69,
    name: "admin-notice-create"
  }, {
    path: "/admin/project/create",
    component: _6b12c8cc,
    name: "admin-project-create"
  }, {
    path: "/admin/statistics/train",
    component: _b15e1694,
    name: "admin-statistics-train"
  }, {
    path: "/admin/statistics/trend",
    component: _fa359c6a,
    name: "admin-statistics-trend"
  }, {
    path: "/admin/user/create",
    component: _e31111d4,
    name: "admin-user-create"
  }, {
    path: "/admin/user/reset",
    component: _0f56e530,
    name: "admin-user-reset"
  }, {
    path: "/monitoring/inspection/option",
    component: _1f28097a,
    name: "monitoring-inspection-option"
  }, {
    path: "/monitoring/inspection/report",
    component: _11ab5082,
    name: "monitoring-inspection-report"
  }, {
    path: "/monitoring/inspection/sampling",
    component: _76b76435,
    name: "monitoring-inspection-sampling"
  }, {
    path: "/monitoring/line/index_dummy",
    component: _1dd5ad46,
    name: "monitoring-line-index_dummy"
  }, {
    path: "/monitoring/line/report",
    component: _63719976,
    name: "monitoring-line-report"
  }, {
    path: "/my/projects/create",
    component: _a94d9cb0,
    name: "my-projects-create"
  }, {
    path: "/my/statistics/train",
    component: _245f8196,
    name: "my-statistics-train"
  }, {
    path: "/my/statistics/trend",
    component: _6d37076c,
    name: "my-statistics-trend"
  }, {
    path: "/my/user/create",
    component: _5ba55937,
    name: "my-user-create"
  }, {
    path: "/notices/pri/deep",
    component: _01ea15a2,
    name: "notices-pri-deep"
  }, {
    path: "/notices/pri/edge",
    component: _37b3b5f3,
    name: "notices-pri-edge"
  }, {
    path: "/notices/pri/setup-wizard",
    component: _1953b9f1,
    name: "notices-pri-setup-wizard"
  }, {
    path: "/train/inference/add",
    component: _3f99e97d,
    name: "train-inference-add"
  }, {
    path: "/train/inference/compare",
    component: _affe7f7e,
    name: "train-inference-compare"
  }, {
    path: "/train/learning/add",
    component: _5a2c7bf4,
    name: "train-learning-add"
  }, {
    path: "/train/learning/relearning",
    component: _8d9e6898,
    name: "train-learning-relearning"
  }, {
    path: "/train/processing/add",
    component: _ce16be2e,
    name: "train-processing-add"
  }, {
    path: "/admin/notice/company/create",
    component: _00d24a3b,
    name: "admin-notice-company-create"
  }, {
    path: "/monitoring/line/report/search",
    component: _b343e276,
    name: "monitoring-line-report-search"
  }, {
    path: "/train/processing/add/ai",
    component: _4bf2d6be,
    name: "train-processing-add-ai"
  }, {
    path: "/train/processing/add/ai.bak",
    component: _51c70e3f,
    name: "train-processing-add-ai.bak"
  }, {
    path: "/train/processing/add/augmentation",
    component: _a8277786,
    name: "train-processing-add-augmentation"
  }, {
    path: "/train/processing/add/new",
    component: _138d9e77,
    name: "train-processing-add-new"
  }, {
    path: "/",
    component: _26a8f230,
    name: "index"
  }, {
    path: "/admin/notice/company/:id",
    component: _be80e364,
    name: "admin-notice-company-id"
  }, {
    path: "/admin/user/reset/:id",
    component: _aa126c60,
    name: "admin-user-reset-id"
  }, {
    path: "/train/inference/compare/:id",
    component: _97e45aa8,
    name: "train-inference-compare-id"
  }, {
    path: "/admin/notice/company/:id/edit",
    component: _2b6ad984,
    name: "admin-notice-company-id-edit"
  }, {
    path: "/admin/algorithm/:id",
    component: _3872051a,
    name: "admin-algorithm-id"
  }, {
    path: "/admin/company/:id",
    component: _1d5aec81,
    name: "admin-company-id"
  }, {
    path: "/admin/notice/:id",
    component: _3476b140,
    name: "admin-notice-id"
  }, {
    path: "/admin/project/:id",
    component: _48083a1d,
    name: "admin-project-id"
  }, {
    path: "/admin/user/:id",
    component: _095e6f93,
    name: "admin-user-id"
  }, {
    path: "/monitoring/inspection/:id",
    component: _1a0f1c42,
    name: "monitoring-inspection-id"
  }, {
    path: "/my/projects/:id",
    component: _09acd3c1,
    name: "my-projects-id"
  }, {
    path: "/my/user/:id",
    component: _1047645c,
    name: "my-user-id"
  }, {
    path: "/notices/cs/:id?",
    component: _b0d2f02e,
    name: "notices-cs-id"
  }, {
    path: "/train/inference/:id",
    component: _ede7d154,
    name: "train-inference-id"
  }, {
    path: "/admin/algorithm/:id/edit",
    component: _1693a90e,
    name: "admin-algorithm-id-edit"
  }, {
    path: "/admin/notice/:id/edit",
    component: _2e950fec,
    name: "admin-notice-id-edit"
  }, {
    path: "/admin/project/:id/edit",
    component: _73349ce2,
    name: "admin-project-id-edit"
  }, {
    path: "/admin/project/:id/user",
    component: _44fe9153,
    name: "admin-project-id-user"
  }, {
    path: "/admin/user/:id/edit",
    component: _b950154e,
    name: "admin-user-id-edit"
  }, {
    path: "/monitoring/inspection/:id/cf",
    component: _0ecec226,
    name: "monitoring-inspection-id-cf"
  }, {
    path: "/monitoring/inspection/:id/od",
    component: _232cba98,
    name: "monitoring-inspection-id-od"
  }, {
    path: "/my/projects/:id/edit",
    component: _0eb5536b,
    name: "my-projects-id-edit"
  }, {
    path: "/my/projects/:id/user",
    component: _172c5e2f,
    name: "my-projects-id-user"
  }, {
    path: "/my/user/:id/edit",
    component: _46d4598c,
    name: "my-user-id-edit"
  }, {
    path: "/train/inference/:id/cf",
    component: _78f6e80f,
    name: "train-inference-id-cf"
  }, {
    path: "/train/inference/:id/od",
    component: _e5563efe,
    name: "train-inference-id-od"
  }, {
    path: "/train/inference/:id/sg",
    component: _d75aed00,
    name: "train-inference-id-sg"
  }, {
    path: "/train/learning/:id/cf",
    component: _10617e10,
    name: "train-learning-id-cf"
  }, {
    path: "/train/learning/:id/od",
    component: _0c2d396a,
    name: "train-learning-id-od"
  }, {
    path: "/train/learning/:id/sg",
    component: _132ae269,
    name: "train-learning-id-sg"
  }, {
    path: "/train/processing/:id/cf",
    component: _7f39e866,
    name: "train-processing-id-cf"
  }, {
    path: "/train/processing/:id/od",
    component: _9c777950,
    name: "train-processing-id-od"
  }, {
    path: "/train/processing/:id/sg",
    component: _e18e1dd2,
    name: "train-processing-id-sg"
  }, {
    path: "/admin/project/:id/user/manager",
    component: _1e510724,
    name: "admin-project-id-user-manager"
  }, {
    path: "/monitoring/inspection/:id/result/cf",
    component: _7e2edebc,
    name: "monitoring-inspection-id-result-cf"
  }, {
    path: "/monitoring/inspection/:id/result/od",
    component: _5572edd8,
    name: "monitoring-inspection-id-result-od"
  }, {
    path: "/my/projects/:id/user/manager",
    component: _2274fb6c,
    name: "my-projects-id-user-manager"
  }, {
    path: "/train/inference/:id/result/ad",
    component: _2da8c259,
    name: "train-inference-id-result-ad"
  }, {
    path: "/train/inference/:id/result/cf",
    component: _312ea299,
    name: "train-inference-id-result-cf"
  }, {
    path: "/train/inference/:id/result/od",
    component: _458c9b0b,
    name: "train-inference-id-result-od"
  }, {
    path: "/train/inference/:id/result/sg",
    component: _4c8a440a,
    name: "train-inference-id-result-sg"
  }, {
    path: "/train/processing/:id/cf/index.back",
    component: _71c2191f,
    name: "train-processing-id-cf-index.back"
  }, {
    path: "/train/processing/:id/cf/result",
    component: _1c051db9,
    name: "train-processing-id-cf-result"
  }, {
    path: "/train/processing/:id/od/index.back",
    component: _7694c56d,
    name: "train-processing-id-od-index.back"
  }, {
    path: "/train/processing/:id/od/result",
    component: _3ac62107,
    name: "train-processing-id-od-result"
  }, {
    path: "/train/processing/:id/sg/index.back",
    component: _5df1d70e,
    name: "train-processing-id-sg-index.back"
  }, {
    path: "/train/processing/:id/sg/result",
    component: _0be72b28,
    name: "train-processing-id-sg-result"
  }, {
    path: "/notices/:id",
    component: _3a36fbdc,
    name: "notices-id"
  }, {
    path: "/pdfViewer/:id?",
    component: _1044e865,
    name: "pdfViewer-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
