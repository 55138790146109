
export default {
  data() {
    return {
      isShow: false,
      // url: 'https://pri-aiplatform-cs.lge.com',
      category: '1',
    };
  },
  computed: {
    isPublicTheme() {
      return this.$store.state.product_name === 'PUBLIC_GCP';
    },
    url() {
      return this.$store.getters.csUrl;
    },
    currentUrl() {
      const currentRoute = this.$store.getters.currentRouteName;
      return currentRoute && currentRoute.split('-')[0] === 'train' && currentRoute.split('-')[1]
        ? currentRoute.split('-')[1]
        : currentRoute;
    },
    platformFaqLink() {
      if (this.currentUrl === 'dataset')
        // 데이터 관리
        this.category = '2';
      else if (this.currentUrl === 'processing')
        // 데이터 가공
        this.category = '3';
      else if (this.currentUrl === 'learning')
        // 학습 수행
        this.category = '4';
      else if (this.currentUrl === 'inference')
        // 성능 평가
        this.category = '5';
      else if (this.currentUrl === 'auth-login')
        // 로그인 페이지
        this.category = '1';
      else {
        // index, train
        this.category = '1,2,3,4,5';
      }
      return `${this.url}/faq?type=1&platform=1&category=${this.category}`;
    },
    platformLink() {
      return this.token ? `${this.url}?type=1&key=${this.token}` : this.url;
    },
  },
  methods: {
    moveToCs() {
      const move = document.getElementById('moveToCs');
      const token = sessionStorage.getItem('x-access-mavin');
      const externalUrl = token ? `${this.url}?type=1&key=${token}` : this.url;
      move.setAttribute('href', externalUrl);
    },
    close() {
      this.isShow = false;
    },
  },
};
