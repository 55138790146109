
import SearchSelect from '../common/SearchSelect.vue';

export default {
  data() {
    return {
      isShowAlarm: false,
      alarms: [],
      selectedProject: null,
      selectedLine: null,
      isArrow: false,
      isLangArrow: false,
      selected: { text: null, value: null },
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.token;
    },
    isHome() {
      return this.$route.path === '/';
    },
    isStatus() {
      return this.$route.path === '/';
    },
    isTrain() {
      return this.$route.path.includes('/train');
    },
    isMonitoring() {
      return this.$route.path.includes('/monitoring');
    },
    isUplusTheme() {
      return this.$store.state.product_name === 'UPLUS';
    },
    isDefaultBrand() {
      return this.$store.state.product_name === 'ONPREMISE_HPC';
    },
    isPublicTheme() {
      return this.$store.state.product_name === 'PUBLIC_GCP';
    },
    userInfo() {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo : {};
    },
    isSystem() {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo.role_id === 1 : false;
    },
    projectOptions() {
      return this.$store.state.user && this.$store.state.user.projects.length > 0
        ? this.$store.state.user.projects.map((project) => {
            return {
              ...project,
              value: project.code,
              text: project.title,
            };
          })
        : [];
    },
    selectedProjectOptionIdx() {
      return this.projectOptions.length > 0
        ? this.projectOptions.findIndex((prj) => prj.code === this.selectedProject)
        : 0;
    },
    lineOptions() {
      return this.projectOptions[this.selectedProjectOptionIdx] &&
        this.projectOptions[this.selectedProjectOptionIdx].lines
        ? this.projectOptions[this.selectedProjectOptionIdx].lines.map((line) => {
            return {
              ...line,
              value: line.code,
              text: line.line_name || line.name,
            };
          })
        : [];
    },
    isShowDownloadRequestModal() {
      return this.$store.getters.isShowDownloadRequestModal;
    },
    languages() {
      return this.$i18n.locales.map((locale) => {
        return {
          ...locale,
          label: this.$t(`language.${locale.code}`),
        };
      });
    },
    currentLanguageLabel() {
      return this.$t(`language.${this.$i18n.locale}`);
    },
    currentProject() {
      const project = this.$store.getters.current_project;
      return {
        value: project.code,
        text: project.title,
      };
    },
  },
  watch: {
    '$store.state.user.projects': {
      deep: true,
      handler(val) {
        if (this.$store.getters.current_project) {
          this.selectedProject = this.$store.getters.current_project.code;
        }
      },
    },
    '$store.getters.current_project': {
      deep: true,
      handler(val) {
        this.selectedProject = val.code;
        //default selected line
        const currentProject = this.projectOptions.find((project) => {
          return project.code === val.code;
        });
        if (currentProject.lines && currentProject.lines.length > 0) {
          this.selectedLine = currentProject.lines[0].code;
          this.$store.dispatch('setCurrentLine', currentProject.lines[0]);
        } else this.selectedLine = null;
      },
    },
    '$store.getters.current_line': {
      deep: true,
      handler(val) {
        this.selectedLine = val.code;
      },
    },
    isShowDownloadRequestModal(val) {
      if (val) {
        this.$root.$emit('bv::show::modal', 'downloadRequestList');
      } else {
        this.$root.$emit('bv::hide::modal', 'downloadRequestList');
      }
    },
  },
  mounted() {
    this.isShowAlarm = false;
    if (this.$store.getters.current_project && this.$store.getters.userInfo) {
      if (
        this.$store.getters.current_project.userId === this.$store.getters.userInfo.id &&
        this.$store.state.user.projects.find((prj) => {
          return prj.code === this.$store.getters.current_project.code;
        })
      ) {
        // if (this.isHome || this.isTrain || this.isMonitoring || this.$store.getters.current_project.hasDenied) {
        //   this.changeProject(this.$store.getters.current_project.code);
        // }
        this.selectedProject = this.$store.getters.current_project.code;
        // this.defaultProject = this.$store.getters.current_project;
      }
    }
    if (this.$store.getters.current_line) {
      this.selectedLine = this.$store.getters.current_line.code;
    }
    document.addEventListener('click', this.onClickBody);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickBody);
  },
  methods: {
    onClickBody(evnt) {
      if (this.isShowAlarm && !this.$refs.noticeCard.contains(evnt.target)) this.isShowAlarm = false;
    },
    async changeProject(projectCode) {
      const currentProject = this.projectOptions.find((project) => project.code === projectCode);
      const hasLimit = await this.$axios.$get(`/api/v2/project-limit/${currentProject.id}/get`);
      if (hasLimit.hasOwnProperty('data')) {
        const end_date = hasLimit.data.end_date;
        // 현재 날짜와 비교
        const today = new Date();
        const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const endDate = new Date(end_date);
        const isDateValid = endDate >= currentDate;
        if (isDateValid) {
          await this.setProject(currentProject);
        } else {
          await this.showAccessDeniedMessage(currentProject);
        }
      } else {
        await this.setProject(currentProject);
      }
      await this.$router.push('/');
    },
    async setProject(currentProject) {
      await this.$store.dispatch('setCurrentProject', currentProject);
      if (currentProject.lines && currentProject.lines.length > 0) {
        this.selectedLine = currentProject.lines[0].code;
        await this.$store.dispatch('setCurrentLine', currentProject.lines[0]);
      } else {
        this.selectedLine = null;
      }
    },
    async showAccessDeniedMessage(currentProject) {
      const hasDeniedProject = {
        ...currentProject,
        hasDenied: true,
      };
      await this.$store.dispatch('setCurrentProject', hasDeniedProject);
      // this.selectedProject = null;
      this.flashMessage.error({
        title: this.$i18n.t('home.error.projectDeniedTitle'),
        message: this.$i18n.t('home.error.projectDeniedContent'),
      });
    },
    async changeLine(lineCode) {
      const currentLine = this.lineOptions.find((line) => {
        return line.code === lineCode;
      });
      await this.$store.dispatch('setCurrentLine', currentLine);
      await this.$router.push('/monitoring');
    },
    async showNotifications() {
      if (!this.isShowAlarm) {
        await this.getNotifications();
      }
      this.isShowAlarm = !this.isShowAlarm;
    },
    async getNotifications() {
      const res = await this.$axios.$get('/api/notifications', {
        params: {
          perPage: 10,
        },
      });
      this.alarms = res.data.data.map((item) => {
        return {
          ...item,
          type_name: this.getTypeNameShort(item.type),
          content: this.getNotificationContent(item),
          project_name: this.projectOptions.find((prj) => prj.id === item.project_id).text,
        };
      });
    },
    getNotificationContent(notification) {
      const { type, status, progress } = notification;
      const type_name = this.getTypeNameLong(type);
      // return  `${type_name} > 작업이 ${status === 'done'? '완료' : '진행' + (progress? '('+progress+')' : '')} 되었습니다.`
      return status === done
        ? `${type_name} ${$t('layoutGlobalNavigationBar.done')}`
        : `${type_name} ${$t('layoutGlobalNavigationBar.process')} ${progress ? '(' + progress + ')' : ''}`;
    },
    showDownloadRequestModal() {
      this.$store.dispatch('showDownloadRequestModal');
    },
    getTypeNameShort(type) {
      switch (type) {
        case 'labeling':
          return this.$i18n.t('layoutGlobalNavigationBar.shortName.labeling');
          break;
        case 'compare_inference':
          return this.$i18n.t('layoutGlobalNavigationBar.shortName.modeling');
          break;
        case 'compare':
          return this.$i18n.t('layoutGlobalNavigationBar.shortName.compare');
          break;
        case 'learning':
          return this.$i18n.t('layoutGlobalNavigationBar.shortName.training');
          break;
        case 'inference':
          return this.$i18n.t('layoutGlobalNavigationBar.shortName.infernece');
          break;
        default:
          return '';
      }
    },
    getTypeNameLong(type) {
      switch (type) {
        case 'labeling':
          return this.$i18n.t('layoutGlobalNavigationBar.longName.labeling');
          break;
        case 'compare_inference':
          return this.$i18n.t('layoutGlobalNavigationBar.longName.modeling');
          break;
        case 'compare':
          return this.$i18n.t('layoutGlobalNavigationBar.longName.compare');
          break;
        case 'learning':
          return this.$i18n.t('layoutGlobalNavigationBar.longName.training');
          break;
        case 'inference':
          return this.$i18n.t('layoutGlobalNavigationBar.longName.infernece');
          break;
        default:
          return '';
      }
    },
    getMomentDiff(notification) {
      if (!notification.updated_at) return '';
      // const notices = this.alarms.filter(item => { return item.job_id === notification.job_id })
      // if(notices.length < 2) return ''
      //const firstNoti = notices.reduce(function(prev, curr) {
      //    return prev.created_at < curr.created_at ? prev : curr;
      //});
      //const diff = this.$moment.duration(this.$moment(notification.created_at).diff(this.$moment(firstNoti.created_at)))
      const diff = this.$moment.duration(
        this.$moment(notification.updated_at).diff(this.$moment(notification.created_at)),
      );
      //console.log("diff::", diff)
      const value = [];
      if (diff.hours() > 0) value.push(diff.hours() + '시간');
      if (diff.minutes() > 0) value.push(diff.minutes() + '분');
      if (diff.seconds() > 0) value.push(diff.seconds() + '초');
      // return value.join(' ') + '소요'
      let current = value.join(' ') + '소요';
      if (this.$i18n.locale === 'ko') {
        return current;
      } else {
        if (current.indexOf('시간') > -1) {
          current = current.replace('시간', this.$i18n.t('layoutGlobalNavigationBar.hour'));
        }
        if (current.indexOf('분') > -1) {
          current = current.replace('시간', this.$i18n.t('layoutGlobalNavigationBar.min'));
        }
        if (current.indexOf('초') > -1) {
          current = current.replace('초', this.$i18n.t('layoutGlobalNavigationBar.sec'));
        }
        return current + this.$i18n.t('layoutGlobalNavigationBar.take');
      }
      // return value.join(' ') + this.$i18n.t('layoutGlobalNavigationBar.take')
    },
    async logout(confirm) {
      if (confirm) {
        await this.$store.dispatch('logout');
        await this.$router.push('/auth/login');
      }
    },
    changeLang(lang) {
      this.$i18n.setLocale(lang);
    },
    validateSelection(selection) {
      this.selected = selection;
      // 일치하는 code가 있으면 그 프로젝트로 스토어 데이터 세팅
      const matchedProject = this.projectOptions.filter((project) => selection.text === project.text);
      if (matchedProject.length !== 0) {
        // this.selectedProject = matchedProject[0];
        this.changeProject(matchedProject[0].value);
      }
    },
  },
  components: { 'search-select': SearchSelect },
};
