import { render, staticRenderFns } from "./GlobalNavigationBar.vue?vue&type=template&id=03e6d008"
import script from "./GlobalNavigationBar.vue?vue&type=script&lang=js"
export * from "./GlobalNavigationBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarkupResultNone: require('/usr/src/app/client/components/markup/ResultNone.vue').default,ModalDatasetDownloadRequestList: require('/usr/src/app/client/components/modal/dataset/DownloadRequestList.vue').default,DefaultConfirm: require('/usr/src/app/client/components/DefaultConfirm.vue').default})
