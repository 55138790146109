
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: [],
    },
    placeholder: {
      type: String,
      required: false,
    },
    defaultOption: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
      showAllOptions: false,
    };
  },
  mounted() {
    // 첫 진입시 default select 값 세팅
    if (this.defaultOption) {
      this.selected = this.defaultOption;
      this.optionsShown = false;
      this.searchFilter = this.defaultOption.text;
    }
  },
  computed: {
    filteredOptions() {
      if (this.showAllOptions) {
        return this.options;
      } else {
        const pattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/g;
        let searchFilterStr = '';
        const searchFilterArr = this.searchFilter.split('');
        if (pattern.test(this.searchFilter)) {
          searchFilterArr.forEach((char) => {
            if (char === '[' || char === ']') {
              searchFilterStr += '\\';
            }
            searchFilterStr += char;
          });
        } else {
          searchFilterStr = this.searchFilter;
        }
        const regOption = new RegExp(searchFilterStr, 'ig');
        return this.options.filter((option) => searchFilterStr.length < 1 || option.text.match(regOption));
      }
    },
    getShowAllOptions() {
      return this.showAllOptions;
    },
    isOpen() {
      return this.optionsShown ? open : '';
    },
    getWidth() {
      let width = '';
      if (this.size === 'sm') width = '200';
      else if (this.size === 'md') width = '300';
      else if (this.size === 'lg') width = '390';
      return `width:${width}px;`;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.text;
      this.$emit('selected', this.selected);
    },
    exit() {
      if (!this.selected.value) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.text;
      }
      this.optionsShown = false;
    },
    handleKeyEvent: function (event) {
      if (event.key === 'Enter' && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
    },
    handleClickInput() {
      this.showAllOptions = true;
      this.optionsShown = !this.optionsShown;
    },
    changeInput() {
      this.searchFilter !== '' ? (this.showAllOptions = false) : (this.showAllOptions = true);
    },
  },
};
